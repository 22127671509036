import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Project } from '@swagger/models';
import { ProjectService } from '@swagger/services/project.service';

export const ProjectActions = createActionGroup({
  events: {
    'Decrease Notifications Counter': emptyProps(),

    'Load Projects': props<{
      payload?: ProjectService.ProjectListListParams;
    }>(),
    'Load Projects Failure': props<{ error: unknown }>(),
    'Load Projects Success': props<{ projects: Project[] }>(),

    'Set Active Project': props<{ project?: Project }>(),
  },
  source: 'Project',
});
