import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { Project } from '@swagger/models';

import { ProjectActions } from './project.actions';

interface State {
  activeProject: Project | undefined;
  error: unknown;
  loading: boolean;
  projects: Project[];
}

const initialState: State = {
  activeProject: undefined,
  error: undefined,
  loading: false,
  projects: [],
};

const reducer = createReducer(
  initialState,
  on(
    ProjectActions.loadProjects,
    (state): State => ({
      ...state,
      loading: true,
    }),
  ),
  on(
    ProjectActions.loadProjectsSuccess,
    (state, { projects }): State => ({
      ...state,
      error: undefined,
      loading: false,
      projects,
    }),
  ),
  on(
    ProjectActions.loadProjectsFailure,
    (state, { error }): State => ({
      ...state,
      error,
      loading: false,
    }),
  ),
  on(
    ProjectActions.setActiveProject,
    (state, { project }): State => ({
      ...state,
      activeProject: project,
    }),
  ),
  on(
    ProjectActions.decreaseNotificationsCounter,
    (state): State => ({
      ...state,
      projects: state.projects.map((project) => {
        return project.id === state.activeProject?.id
          ? {
              ...project,
              notification_count: project.notification_count - 1,
            }
          : project;
      }),
    }),
  ),
);

export const projectFeature = createFeature({
  extraSelectors: ({ selectProjects }) => ({
    selectArchiveProjects: createSelector(selectProjects, (projects) =>
      projects.filter((project) => project.status === 'archive'),
    ),
    selectProjectsWithContracts: createSelector(selectProjects, (projects) =>
      projects.filter(
        (project) => project.contract_count && project.status !== 'archive',
      ),
    ),
    selectProjectsWithoutContracts: createSelector(selectProjects, (projects) =>
      projects.filter(
        (project) => !project.contract_count && project.status !== 'archive',
      ),
    ),
  }),
  name: 'project',
  reducer,
});
