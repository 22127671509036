import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ProjectService } from '@swagger/services/project.service';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { ProjectActions } from './project.actions';

@Injectable()
export class ProjectEffects {
  loadProjects$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectActions.loadProjects),
      exhaustMap(({ payload }) =>
        this.projectService
          .projectListList({ ...payload, ordering: 'date_start' })
          .pipe(
            map((projects) => ProjectActions.loadProjectsSuccess({ projects })),
            catchError((error: unknown) =>
              of(
                ProjectActions.loadProjectsFailure({
                  error:
                    (<HttpErrorResponse>error)?.error ??
                    'Error while loading projects',
                }),
              ),
            ),
          ),
      ),
    );
  });

  constructor(
    private actions$: Actions,
    private projectService: ProjectService,
  ) {}
}
